<template>
  <v-row>
    <v-col>
      <v-card outlined :loading="form.loading" :disabled="form.loading">
        <v-card-title>
          Add Verified Member
          <v-btn class="ml-2" icon @click="$refs.verified.reset()">
              <v-icon>mdi-reload</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-avatar color="indigo">
            <v-icon color="white">mdi-account-check</v-icon>
          </v-avatar>
        </v-card-title>
        <v-card-text>
          <v-form ref="verified">
            <v-row>
              <v-col>
                <v-autocomplete
                  label="Country"
                  :items="countries.list"
                  item-text="name"
                  item-value="iso2"
                  :loading="countries.loading"
                  :disabled="countries.loading"
                  @change="getStates"
                  v-model="countries.selected"
                  :rules="form.rules.country"
                ></v-autocomplete>
              </v-col>
              <v-col>
                <v-autocomplete
                  label="State/Province"
                  :items="states.list"
                  item-text="name"
                  item-value="iso2"
                  :loading="states.loading"
                  :disabled="!countries.selected || states.loading"
                  @change="getCities"
                  v-model="states.selected"
                  :rules="form.rules.state"
                ></v-autocomplete>
              </v-col>
              <v-col>
                <v-autocomplete
                  label="District/City"
                  :items="cities.list"
                  item-text="name"
                  item-value="name"
                  :disabled="!states.selected"
                  v-model="cities.selected"
                  :rules="form.rules.district"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Name"
                  v-model="form.data.name"
                  :rules="form.rules.name"
                ></v-text-field>
                <v-autocomplete
                  label="Status"
                  :items="form.status"
                  item-text="text"
                  item-value="value"
                  v-model="form.data.status"
                  :rules="form.rules.status"
                ></v-autocomplete>
              </v-col>
              <v-col>
                <v-text-field
                  label="Website ID"
                  v-model="form.data.website_id"
                  :rules="form.rules.website_id"
                ></v-text-field>
                <v-text-field
                  label="Upline Website ID"
                  v-model="form.data.upline"
                  :rules="form.rules.upline"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Phone"
                  v-model="form.data.phone"
                  :rules="form.rules.phone"
                >
                  <template v-slot:append>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">mdi-information</v-icon>
                      </template>

                      <span
                        >Angka yang pertama kali muncul merupakan kode
                        area</span
                      >
                    </v-tooltip>
                  </template>
                </v-text-field>
                <v-autocomplete
                  label="Active"
                  :items="form.active"
                  item-text="text"
                  item-value="value"
                  v-model="form.data.is_active"
                  :rules="form.rules.is_active"
                ></v-autocomplete>
              </v-col>
              <v-col>
                <v-textarea
                  label="Address"
                  v-model="form.data.address"
                  :rules="form.rules.address"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="indigo accent-2" @click="saveData" large>
            <span class="white--text">save</span>
            <v-icon right color="white">mdi-content-save</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color" multi-line>
      <v-icon left>
        {{ snackbar.icon }}
      </v-icon>
      {{ snackbar.text }}

      <template v-slot:action>
        <v-btn text @click="snackbar.show = false">
          <span>close</span>
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      config: {
        withCredentials: false,
        headers: {
          "X-CSCAPI-KEY": process.env.VUE_APP_COUNTRY_STATE_API_KEY,
        },
      },
      countries: {
        loading: false,
        detail: null,
        selected: null,
        list: null,
      },
      states: {
        loading: false,
        detail: null,
        selected: null,
        list: null,
      },
      cities: {
        loading: false,
        detail: null,
        selected: null,
        list: null,
      },
      form: {
        status: [
          { text: "Agen", value: "agen" },
          { text: "Distributor", value: "distributor" },
          { text: "Reseller", value: "reseller" },
        ],
        active: [
          { text: "Yes", value: "yes" },
          { text: "No", value: "no" },
        ],
        loading: false,
        data: {
          website_id: null,
          name: null,
          phone: null,
          address: null,
          country: null,
          state: null,
          district: null,
          status: null,
          upline: null,
          is_active: null,
          profile_image: null,
          link: null,
        },
        rules: {
          name: [(v) => !!v || "Name is required"],
          website_id: [(v) => !!v || "Website ID is required"],
          phone: [(v) => !!v || "Phone is required"],
          address: [(v) => !!v || "Address is required"],
          country: [(v) => !!v || "Country is required"],
          state: [(v) => !!v || "State is required"],
          district: [(v) => !!v || "District is required"],
          status: [(v) => !!v || "Status is required"],
          upline: [(v) => !!v || "Upline is required"],
          is_active: [(v) => !!v || "Is Active is required"],
        },
      },
      snackbar: {
        show: false,
        color: null,
        text: null,
        icon: null,
      },
    };
  },
  methods: {
    ...mapActions(["addMember"]),
    saveData() {
      let data = this.form.data;
      data.country = this.countries.selected;
      data.state = this.states.selected;
      data.district = this.cities.selected;

      this.form.loading = true;

      let isValid = this.$refs.verified.validate();

      if (isValid) {
        // console.log(data);
        if (this.addMember(data)) {
          this.form.loading = false;
          this.showNotification("success");
        } else {
          this.form.loading = false;
          this.showNotification("error");
        }
      } else {
        this.form.loading = false;
        this.showNotification("error");
      }
    },
    async getCountries() {
      this.form.loading = true;
      await axios.get(`${process.env.VUE_APP_API_URL}/api/countries`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        }
      })
      .then(response => {
        this.countries.list = response.data;
        this.form.loading = false;
      })
      .catch(err => {
        this.form.loading = false;
        console.log(err);
      });
      /*await axios
        .get("https://api.countrystatecity.in/v1/countries", this.config)
        .then((response) => {
          this.countries.list = response.data;
          //   console.log(response.data);
          this.form.loading = false;
        })
        .catch((err) => {
          this.form.loading = false;
          console.log(err);
        });*/
    },
    async getStates() {
      this.countries.loading = true;

      await axios.get(`${process.env.VUE_APP_API_URL}/api/countries/${this.countries.selected}/states`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        }
      })
      .then(response => {
        this.states.list = response.data;
        this.getDetails("country", this.countries.selected);
        this.countries.loading = false;
      })
      .catch(err => {
        this.countries.loading = false;
        console.log(err);
      });
      /*await axios
        .get(
          `https://api.countrystatecity.in/v1/countries/${this.countries.selected}/states`,
          this.config
        )
        .then((response) => {
          this.countries.loading = false;
          this.states.list = response.data;
          //   console.log(response.data);
          this.getDetails("country", this.countries.selected);
        })
        .catch((err) => {
          this.countries.loading = false;
          console.log(err);
        });*/
    },

    async getCities() {
      this.states.loading = true;

      await axios.get(`${process.env.VUE_APP_API_URL}/api/countries/${this.countries.selected}/states/${this.states.selected}/cities`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        }
      })
      .then(response => {
        this.cities.list = response.data;
        this.getDetails("state", this.states.selected);
        this.states.loading = false;
      })
      .catch(err => {
        this.states.loading = false;
        console.log(err);
      });
      /*await axios
        .get(
          `https://api.countrystatecity.in/v1/countries/${this.countries.selected}/states/${this.states.selected}/cities`,
          this.config
        )
        .then((response) => {
          this.states.loading = false;
          this.cities.list = response.data;
          //   console.log(response.data);
          this.getDetails("state", this.states.selected);
        })
        .catch((err) => {
          this.states.loading = false;
          console.log(err);
        });*/
    },
    async getDetails(type = "", code = "") {
      switch (type) {
        case "country": {
          await axios.get(`${process.env.VUE_APP_API_URL}/api/countries/${code}`, {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem("token")}`,
            }
          })
          .then(response => {
            this.countries.detail = response.data;
            this.setPhoneCode(response.data.phonecode)
          })
          .catch(err => {
            console.log(err);
          });
          /*await axios
            .get(
              `https://api.countrystatecity.in/v1/countries/${code}`,
              this.config
            )
            .then((response) => {
              this.countries.detail = response.data;
              this.setPhoneCode(response.data.phonecode);
              //   console.log(response.data);
            })
            .catch((err) => {
              console.log(err);
            });*/
          break;
        }
        case "state": {
          await axios.get(`${process.env.VUE_APP_API_URL}/api/countries/${this.countries.selected}/states/${code}`, {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem("token")}`,
            }
          })
          .then(response => {
            this.states.detail = response.data;
          })
          .catch(err => {
            console.log(err);
          });
          /*await axios
            .get(
              `https://api.countrystatecity.in/v1/countries/${this.countries.selected}/states/${code}`,
              this.config
            )
            .then((response) => {
              this.states.detail = response.data;
              //   console.log(response.data);
            })
            .catch((err) => {
              console.log(err);
            });*/
          break;
        }
      }
    },
    setPhoneCode(code) {
      let arr = code.split("");
      if (arr[0] == "+") {
        this.form.data.phone = arr.join("");
      } else {
        arr = ["+", ...arr];
        this.form.data.phone = arr.join("");
      }
      //   this.form.data.phone = code;
      console.log(this.form.data.phone);
    },
    showNotification(status = "") {
      this.snackbar.show = true;
      switch (status) {
        case "success":
          this.snackbar.color = "success";
          this.snackbar.text = "Successfully Added";
          this.snackbar.icon = "mdi-check-circle";
          break;
        case "error":
          this.snackbar.color = "error";
          this.snackbar.text = "Error Occured";
          this.snackbar.icon = "mdi-close-circle";
          break;
      }
    },
  },
  mounted() {
    this.getCountries();
  },
};
</script>